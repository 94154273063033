.explore-login-container {
    width: 100vw;
    position: relative;
    display: flex;
    flex-flow: column;
    height: calc(100vh - 58px) !important
  }
  
  .banner-box {
    text-align: center;
    margin: 15px;
  }
  
  .banner-wrapper {
    position: relative;
    text-align: center;
  }
  
  .container-title {
    height: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #000;
    margin: 20px 10px;
  }
  
  .button-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
  }
  
  .btn-login {
    position: relative;
    background-color: #670000;
    color: #fff;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    font-weight: 700;
    width: 70%;
    text-align: center;
    outline: none;
    border-radius: 100px;
  }
  
  .conditions-box a {
    color: #686b78;
  }
  
  .button-panel {
    display: flex;
    justify-content: center;
  }
  
  .bottom-button {
    width: 100%;
    background-color: #670000;
    margin: 20px 0;
    height: 100%;
    border-radius: 40px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    transition: all 0.2s;
    position: relative;
  }
  
  .button--loading .bottom-button {
    visibility: hidden;
    opacity: 0;
  }
  
  .button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  
  .disabled {
    opacity: 0.5;
  }
  
  .checkout-container .payon_delivery-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  
  .payon_delivery-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    align-items: center;
    margin-bottom: 5rem;
  }
  
  .payon_delivery-container h6 {
    font-weight: bold;
    width: 60%;
  }
  
  .signup-progress {
    display: block;
    width: 80%;
    height: 10px;
    margin: auto;
  }
  
  progress.signup-progress::-webkit-progress-bar {
    background-color: #aaaaaa;
  }
  
  progress.signup-progress::-webkit-progress-value {
    background-color: #670000;
  }
  
  .signup-progress-container {
    margin-bottom: 20px;
  }
  
  .signup-progress-container p {
    margin: 10px auto;
    text-align: center;
    padding-bottom: 0px;
  }
  
  .disabled {
    opacity: 0.5;
  }
  
  .payon_delivery-container input[type='checkbox'] {
    position: inherit;
    opacity: 1;
    width: 30px;
    pointer-events: inherit;
  }
  
  p.pay-info {
    margin: 0px;
  }
  